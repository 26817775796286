import {authFetch} from "./auth-helpers";

export interface GetSlackUrlResponse {
    url?: string;
    error?: string;
}

export interface SaveMonitorResponse {
    monitor: Monitor;
    error?: string;
}

export interface GetMonitorResponse {
    monitor?: Monitor;
    error?: string;
}

export interface TestMonitorResponse {
    success: boolean;
    message: string;
}

export interface MonitorsResponse {
    monitors: Monitor[];
    nextToken?: string;
    error?: string;
}

export interface MonitorEventsResponse {
    events: MonitorEventData[];
    error?: string;
}

export interface Monitor {
    id?: string;
    name: string;
    type: string;
    method: string;
    authMode: string;
    authValue: string;
    requestBody: string;
    target: string;
    cadence: string;
    notificationChannels: string[];
    state: string;
    timeout: number;
}

export interface MonitorEventData {
    type: string;
    timestamp: number;
    latency?: number;
    success: boolean;
    message?: string;
    state: string;
}

class MonitorsDatastore {
    constructor() {
    }

    addMonitor(monitor: Monitor): Promise<SaveMonitorResponse> {
        return authFetch("https://api.service-availability.io/monitors", {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(monitor)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        monitor: result.monitor,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        monitor: monitor,
                        error: error
                    }
                }
            );
    }

    saveMonitor(monitor: Monitor): Promise<SaveMonitorResponse> {
        return authFetch(`https://api.service-availability.io/monitors/${monitor.id}`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(monitor)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        monitor: result.monitor,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        monitor: monitor,
                        error: error
                    }
                }
            );
    }

    loadMonitor(id: string): Promise<GetMonitorResponse> {
        return authFetch(`https://api.service-availability.io/monitors/${id}`, {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        monitor: result.monitor,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        error: error,
                    }
                }
            );
    }

    loadMonitors(nextToken?: string): Promise<MonitorsResponse> {
        return authFetch(`https://api.service-availability.io/monitors${nextToken ? '?nextToken=' + nextToken : ''}`, {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        monitors: result.monitors,
                        nextToken: result.nextToken
                    }
                },
                (error) => {
                    return {
                        monitors: [],
                        error: error,
                    }
                }
            );
    }

    loadMonitorEvents(monitorId: string): Promise<MonitorEventsResponse> {
        return authFetch(`https://api.service-availability.io/monitor/${monitorId}/events`, {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        events: result.events.map((e:string) => JSON.parse(e)),
                    }
                },
                (error) => {
                    return {
                        events: [],
                        error: error,
                    }
                }
            );
    }

    testMonitor(monitor: Monitor): Promise<TestMonitorResponse> {
        // return {success: false, error: "Something went wrong"};
        return authFetch("https://api.service-availability.io/test", {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({monitor: monitor}),
        })
            .then(res => res.json())
            .catch( err => {
                if (typeof err.text === 'function') {
                    return err.text().then((errorMessage:any) => {
                        console.log("fn", errorMessage)
                        return {
                            success: false,
                            message: errorMessage,
                        }
                    });
                }
                return {
                    success: false,
                    message: err.message,
                }
            })
            .then(
                (result) => {
                    return {
                        success: result.success,
                        message: result.message,
                    }
                },
                (error) => {
                    return {
                        success: false,
                        message: error.message,
                    }
                }
            );
    }
}

export default MonitorsDatastore;