import MonitorsDatastore, {Monitor, SaveMonitorResponse, TestMonitorResponse} from "../../stores/monitors-datastore";
import NotificationsDatastore, {NotificationChannel} from "../../stores/notifications-datastore";
import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface MonitorEditorProps {
    monitorsDatastore: MonitorsDatastore;
    notificationsDatastore: NotificationsDatastore;
    originalMonitor?: Monitor;
    onMonitorSaved?: (response: SaveMonitorResponse) => void;
    onDiscard?: () => void;
}

function MonitorEditor(props: MonitorEditorProps) {
    // monitor state
    const [monitorName, setMonitorName] = useState(props.originalMonitor?.name ?? '');
    const [monitorMethod, setMonitorMethod] = useState(props.originalMonitor?.method ?? 'GET');
    const [monitorAuth, setMonitorAuth] = useState(props.originalMonitor?.authMode ?? 'None');
    const [monitorAuthValue, setMonitorAuthValue] = useState(props.originalMonitor?.authValue ?? '');
    const [monitorTarget, setMonitorTarget] = useState(props.originalMonitor?.target ?? '');
    const [monitorRequestBody, setMonitorRequestBody] = useState(props.originalMonitor?.requestBody ?? '');
    const [slackCheck, setSlackCheck] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [smsCheck, setSmsCheck] = useState(false);
    const [notificationChannels, setNotificationChannels] = useState([] as NotificationChannel[]);
    const [enabledNotificationChannels, setEnabledNotificationChannels] = useState(props.originalMonitor?.notificationChannels ?? []);

    // monitor validation
    const [monitorNameValid, setMonitorNameValid] = useState(true);
    const [monitorAuthValid, setMonitorAuthValid] = useState(true);
    const [monitorTargetValid, setMonitorTargetValid] = useState(true);
    const [monitorRequestBodyValid, setMonitorRequestBodyValid] = useState(true);

    // monitor actions state
    const [addMonitorError, setAddMonitorError] = useState('');
    const [addMonitorInProgress, setAddMonitorInProgress] = useState(false);
    const [addSlackWorkspaceInProgress, setAddSlackWorkspaceInProgress] = useState(false);
    const [runCurlInProgress, setRunCurlInProgress] = useState(false);
    const [runCurlResult, setRunCurlResult] = useState({success: false, message: '', executed: false});

    useEffect(() => {
        props.notificationsDatastore.loadNotificationChannels().then(response => {
            if (!response.error) {
                setNotificationChannels(response.channels);
            } else {
                console.log("Unable to get notification channels:", response.error);
            }
        });
    }, []);

    const showBasicAuth = monitorAuth === 'Basic' || monitorAuth === 'Custom';
    const showPostRequestBody = monitorMethod === 'POST';
    const currentMonitor = {
        name: monitorName,
        type: 'ping',
        target: monitorTarget,
        authMode: monitorAuth,
        authValue: monitorAuthValue,
        method: monitorMethod,
        requestBody: monitorRequestBody,
        cadence: '1min',
        notificationChannels: enabledNotificationChannels,
        state: 'Unknown',
        timeout: 10,
    };

    function validate(monitor: Monitor): boolean {
        let valid = true;
        if (monitor.name === '') {
            setMonitorNameValid(false);
            valid = false;
        }
        if (monitor.target === '') {
            setMonitorTargetValid(false);
            valid = false;
        }
        if ((monitor.authMode === 'Basic' || monitor.authMode === 'Custom') && monitor.authValue === '') {
            setMonitorAuthValid(false);
            valid = false;
        }
        if (monitor.method === 'POST' && monitor.requestBody === '') {
            setMonitorRequestBodyValid(false);
            valid = false;
        }
        // if(!monitor.notificationChannels || monitor.notificationChannels.length === 0) {
        //     validate = false;
        // }
        return valid;
    }

    function handleSetMonitorName(value: any) {
        setMonitorName(value);
        setMonitorNameValid(true);
    }

    function handleSetMonitorMethod(value: any) {
        setMonitorMethod(value);
        handleSetMonitorRequestBody('');
    }

    function handleSetMonitorTarget(value: any) {
        setMonitorTarget(value);
        setMonitorTargetValid(true);
    }

    function handelSetMonitorAuth(value: any) {
        setMonitorAuth(value);
        handleSetMonitorAuthValue('');
    }

    function handleSetMonitorAuthValue(value: any) {
        setMonitorAuthValue(value);
        setMonitorAuthValid(true);
    }

    function handleSetMonitorRequestBody(value: any) {
        setMonitorRequestBody(value);
        setMonitorRequestBodyValid(true);
    }

    function saveMonitor(monitor: Monitor) {
        if (!validate(monitor)) {
            return;
        }
        setAddMonitorInProgress(true);
        setAddMonitorError('');
        const saveMonitorResponse = monitor.id
            ? props.monitorsDatastore.saveMonitor(monitor)
            : props.monitorsDatastore.addMonitor(monitor);
        saveMonitorResponse.then(response => {
            setAddMonitorInProgress(false);
            if ((response.error as any) instanceof Error) {
                console.log(response.error);
                setAddMonitorError('Unable to save monitor, please try again later');
            } else if (response.error) {
                console.log("Monitor not saved successfully: ", response.error);
                setAddMonitorError(response.error);
            } else {
                console.log("Monitor saved successfully");
                props.onMonitorSaved?.(response);
            }
        }).catch(error => {
            console.log("Monitor not saved successfully (catch): ", error);
        });
    }

    function discard() {
        props.onDiscard?.();
    }

    function channelSelected(channel: NotificationChannel): boolean {
        return enabledNotificationChannels && enabledNotificationChannels.indexOf(channel.id) !== -1;
    }

    function toggleChannelSelection(channel: NotificationChannel): void {
        if (channelSelected(channel)) {
            setEnabledNotificationChannels(enabledNotificationChannels.filter(c => c !== channel.id));
        } else {
            setEnabledNotificationChannels([...enabledNotificationChannels, channel.id]);
        }
    }

    function withDefaults(monitor: Monitor): Monitor {
        const clonedMonitor = Object.assign({}, monitor);
        if (!clonedMonitor.target) {
            clonedMonitor.target = 'https://api.service-availability.io/health';
        }
        return clonedMonitor;
    }

    function curlExample(monitor: Monitor): string {
        const data = monitor.method === 'POST' ? ` -d '${monitor.requestBody}'` : '';
        const url = monitor.target ? monitor.target : 'https://api.service-availability.io/health';
        const auth = monitor.authMode === 'Basic'
            ? ` -H "Authorization: Basic ${monitor.authValue}"`
            : (monitor.authMode === 'Custom' ? ` -H "Authorization: ${monitor.authValue}"` : '');
        return `curl${data}${auth} -X ${monitor.method} ${url}`;
    }

    function runCurl(monitor: Monitor): Promise<TestMonitorResponse> {
        setRunCurlInProgress(true);
        setRunCurlResult({success: true, message: '', executed: false});
        return props.monitorsDatastore.testMonitor(monitor).then((res) => {
            setRunCurlInProgress(false);
            setRunCurlResult({success: res.success, message: res.message, executed: true});
            return res;
        });
    }

    function resetCurlRunResults() {
        setRunCurlResult({success: false, message: '', executed: false});
    }

    function addSlackWorkspace(monitor: Monitor) {
        setAddSlackWorkspaceInProgress(true);
        return props.notificationsDatastore.addSlackWorkspace().then((result) => {
            setAddSlackWorkspaceInProgress(false);
            const url = result.url!!;
            window.open(url);
            // add handler on "window visible"

            // document.addEventListener("visibilitychange", () => {
            //     console.log("you are back!?");
            // });
        });
    }

    function renderNotificationChannels(type: string) {
        function label(c: NotificationChannel): string {
            if (c.type === 'slack') {
                return `Notify ${c.channel} channel in ${c.workspace} workspace`;
            } else if (c.type === 'Email') {
                return `Send email to ${c.handle}`;
            } else if (c.type === 'Sms') {
                return `Send SMS to ${c.handle}`;
            } else {
                return `Notify ${c.handle}`;
            }
        }

        return (
            <List>
                {notificationChannels && notificationChannels.filter(c => c.type === type).map(c =>
                    <ListItem key={"li-" + c.id}>
                        <FormControlLabel key={"fcl-" + c.id} control={
                            <Switch key={"s-" + c.id} checked={channelSelected(c)}
                                    onChange={(event) => toggleChannelSelection(c)}/>
                        } label={label(c)}/>
                    </ListItem>
                )}
            </List>
        );
    }

    return (
        <Box component="form" sx={{'& .MuiTextField-root': {m: 0}}} noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth id="monitorName" label="Name" size="small"
                               onChange={(event) => handleSetMonitorName(event.target.value)}
                               error={!monitorNameValid} value={monitorName}/>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{m: 0}}>
                        <InputLabel id="monitorMethod">Method</InputLabel>
                        <Select labelId="monitorMethod" id="monitorMethodSelect" value={monitorMethod}
                                label="Method" size="small"
                                onChange={(event) => handleSetMonitorMethod(event.target.value)}>
                            <MenuItem value={'GET'}>GET</MenuItem>
                            <MenuItem value={'POST'}>POST</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth id="monitorTarget" label="Url" size="small" value={monitorTarget}
                               placeholder="https://api.service-availability.io/health"
                               InputLabelProps={{shrink: true}} error={!monitorTargetValid}
                               onChange={(event) => handleSetMonitorTarget(event.target.value)}/>

                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{m: 0}}>
                        <InputLabel id="monitorAuth">Auth</InputLabel>
                        <Select labelId="monitorAuth" id="monitorAuthSelect" value={monitorAuth} label="Auth"
                                onChange={(event) => handelSetMonitorAuth(event.target.value)} size="small">
                            <MenuItem value={'None'}>None</MenuItem>
                            <MenuItem value={'Basic'}>Basic</MenuItem>
                            {/*<MenuItem value={'Custom'}>Custom header</MenuItem>*/}
                        </Select>
                    </FormControl>
                </Grid>

                {showPostRequestBody &&
                    <Grid item xs={8}>
                        <TextField fullWidth multiline id="monitorPostRequestBody" label="POST body"
                                   value={monitorRequestBody} size="small" error={!monitorRequestBodyValid}
                                   placeholder='{"key1": "value1"}' InputLabelProps={{shrink: true}}
                                   onChange={(event) => handleSetMonitorRequestBody(event.target.value)}/>

                    </Grid>
                }

                {(showBasicAuth) &&
                    <Grid item xs={4}>
                        <TextField fullWidth id="monitorAuthHeader" label="Authorization Header"
                                   value={monitorAuthValue} size="small" error={!monitorAuthValid}
                                   placeholder="" InputLabelProps={{shrink: true}}
                                   onChange={(event) => handleSetMonitorAuthValue(event.target.value)}/>
                    </Grid>
                }

                <Grid item xs={10}>
                    <Paper sx={{p: 2, color: "secondary.light"}}>{curlExample(withDefaults(currentMonitor))}</Paper>
                </Grid>

                <Grid item xs={1}>
                    <Button variant="contained" onClick={() => runCurl(withDefaults(currentMonitor))} sx={{mt: 1.5}}
                            disabled={runCurlInProgress} size="small">
                        {runCurlInProgress && <CircularProgress size={25} color="inherit"/>}
                        {!runCurlInProgress && 'Test'}
                    </Button>
                </Grid>

                <Grid item xs={1}>
                    {runCurlResult.executed && !runCurlResult.success && <Typography sx={{mt: 2}} color="error.dark">
                        Failure
                    </Typography>}
                    {runCurlResult.executed && runCurlResult.success && <Typography sx={{mt: 2}} color="secondary.dark">
                        Success
                    </Typography>}
                </Grid>

                {runCurlResult.message &&
                    <Grid item xs={12}>
                        <Alert severity={runCurlResult.success ? "info" : "error"}
                               onClose={() => resetCurlRunResults()}>
                            {runCurlResult.message}
                        </Alert>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Divider sx={{color: "secondary.dark"}} textAlign="left" role="presentation"
                             variant="fullWidth">Notifications</Divider>
                </Grid>

                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content"
                                          id="panel1a-header">
                            <Typography>Slack</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* list of notification channels will include all kinds of channels and needs to be loaded separately */}
                            {renderNotificationChannels('slack')}
                            <Button variant="contained" onClick={() => addSlackWorkspace(currentMonitor)}
                                    sx={{m: 3}}
                                    disabled={addSlackWorkspaceInProgress} size="small">
                                {addSlackWorkspaceInProgress && <CircularProgress size={25}/>}
                                {!addSlackWorkspaceInProgress && 'Add Slack workspace'}
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion disabled>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2a-content"
                                          id="panel2a-header">
                            <Typography>Email</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                <ListItem>
                                    <FormControlLabel control={
                                        <Switch checked={slackCheck}
                                                onChange={(event) => setSlackCheck(event.target.checked)}/>
                                    } label="Email notification delivered to &lt;...&gt;"/>
                                </ListItem>
                            </List>
                            <Button variant="contained" onClick={() => saveMonitor(currentMonitor)} sx={{m: 3}}
                                    disabled={addMonitorInProgress} size="small">
                                {addMonitorInProgress && <CircularProgress size={25}/>}
                                {!addMonitorInProgress && 'Add email address'}
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion disabled>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2a-content"
                                          id="panel2a-header">
                            <Typography>SMS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                <ListItem>
                                    <FormControlLabel control={
                                        <Switch checked={slackCheck}
                                                onChange={(event) => setSlackCheck(event.target.checked)}/>
                                    } label="SMS notification delivered to &lt;...&gt;"/>
                                </ListItem>
                            </List>
                            <Button variant="contained" onClick={() => saveMonitor(currentMonitor)} sx={{m: 3}}
                                    disabled={addMonitorInProgress} size="small">
                                {addMonitorInProgress && <CircularProgress size={25}/>}
                                {!addMonitorInProgress && 'Add phone number'}
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} textAlign="right">
                    {addMonitorError &&
                        <Alert severity="error" onClose={() => {
                        }}>
                            {addMonitorError}
                        </Alert>
                    }
                    <Box>
                        <Button variant="contained" onClick={() => saveMonitor(currentMonitor)} sx={{m: 3}}
                                disabled={addMonitorInProgress} size="small">
                            {addMonitorInProgress && <CircularProgress size={25} color={"inherit"}/>}
                            {!addMonitorInProgress && 'Save monitor'}
                        </Button>
                        <Link href="#" variant="body2" onClick={() => discard()}>
                            Cancel
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MonitorEditor;