import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Button, CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";
import Title from "../../components/page-layout/title";
import MonitorsDatastore, {Monitor, MonitorsResponse} from "../../stores/monitors-datastore";

interface MonitorsListState {
    monitors: Monitor[];
    nextToken?: string;
    loading: boolean;
}

interface MonitorsListProps {
    datastore: MonitorsDatastore;
}

class MonitorsList extends React.Component<MonitorsListProps, MonitorsListState> {
    private datastore: MonitorsDatastore;

    constructor(props: MonitorsListProps) {
        super(props);
        this.state = {
            monitors: [],
            loading: false,
        };

        this.datastore = this.props.datastore;
        this.loadMonitors = this.loadMonitors.bind(this);
        this.loadMoreMonitors = this.loadMoreMonitors.bind(this);
    }

    componentDidMount() {
        this.loadMonitors(this.state.nextToken).then(response => {
            this.setState({
                monitors: response.monitors,
            });
        })
    }

    loadMonitors(nextToken?: string): Promise<MonitorsResponse> {
        this.setState({loading: true});
        return this.datastore.loadMonitors(nextToken).then(response => {
            this.setState({loading: false});
            return response;
        });
    }

    loadMoreMonitors(): Promise<any> {
        return this.loadMonitors(this.state.nextToken).then(response => {
            const monitors = [...this.state.monitors];
            const presentMonitorIds = monitors.map(m => m.id);
            response.monitors.forEach(monitor => {
                if(presentMonitorIds.indexOf(monitor.id) === -1) {
                    monitors.push(monitor);
                }
            });
            this.setState({
                monitors: monitors,
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Title>Monitors</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>State</TableCell>
                            {/*<TableCell>Type</TableCell>*/}
                            <TableCell>Method</TableCell>
                            <TableCell>Auth</TableCell>
                            <TableCell>Target</TableCell>
                            <TableCell>Notifications</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.monitors.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell><Link to={`/monitors/${row.id}`}>{row.name}</Link></TableCell>
                                {/*<TableCell>{row.type}</TableCell>*/}
                                <TableCell>{row.state ?? 'Unknown'}</TableCell>
                                <TableCell>{row.method}</TableCell>
                                <TableCell>{row.authMode}</TableCell>
                                <TableCell>{row.target}</TableCell>
                                <TableCell>{row.notificationChannels.length} notification channel(s)</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/*{this.state.loading && <LinearProgress/>}*/}

                {this.state.nextToken && <Button variant="contained" onClick={this.loadMoreMonitors} disabled={this.state.loading} sx={{mt: 3}}>
                    {this.state.loading && <CircularProgress size={14}/>}
                    {!this.state.loading && 'Fetch more monitors'}
                </Button>}
            </React.Fragment>
        );
    }
}

export default MonitorsList;

