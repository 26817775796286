import React from "react";

function NoSuchPage() {
    return (
        <div className="container text-center overflow-hidden min-vh-100">
            <div className="row my-5">
                <div className="col p-3">
                    <p className="h1">No such page</p>
                </div>
            </div>
        </div>
    );
}

export default NoSuchPage;
