import * as React from 'react';
import Title from "../../components/page-layout/title";
import {Box, Button, CircularProgress, Grid, TextField} from "@mui/material";

export default function PaymentDetails() {
    const details = {
        paymentType: 'Visa',
        cardNumber: '**** **** **** 3456',
        cardExpiratonDate: '12/2022',
        cardCode: '***',
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Title>Payment details</Title>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" sx={{'& .MuiTextField-root': {m: 0}}} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            {/*<Grid item xs={1}>*/}
                            {/*    <IconButton onClick={() => {}} color="primary" aria-label="Edit">*/}
                            {/*        <Edit/>*/}
                            {/*    </IconButton>*/}
                            {/*</Grid>*/}
                            <Grid item xs={3}>
                                <TextField id="paymentMethod" label="Type" size="small" value={details.paymentType}
                                           aria-readonly={true} disabled={true} variant="standard"/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="paymentHandle" label="Card number" size="small" value={details.cardNumber}
                                           aria-readonly={true} disabled={true} variant="standard"/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="paymentExpiry" label="Card expiration date" size="small" value={details.cardExpiratonDate}
                                           aria-readonly={true} disabled={true} variant="standard"/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="paymentCode" label="CVV" size="small" value={details.cardCode}
                                           aria-readonly={true} disabled={true} variant="standard"/>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="text" onClick={() => {}} sx={{mt: 0}}
                            disabled={false}>
                        {false && <CircularProgress size={14}/>}
                        {!false && 'Change payment details'}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
