import MonitorsDatastore, {
    SaveMonitorResponse,
    Monitor,
    MonitorsResponse,
    GetMonitorResponse, TestMonitorResponse
} from "./monitors-datastore";

class MockMonitorsDatastore extends MonitorsDatastore {
    private mockStore = [
        {
            id: 'abc1',
            name: 'GET http://localhost:80',
            type: 'ping',
            method: 'GET',
            target: 'http://localhost:80/',
            authMode: 'None',
            authValue: '',
            requestBody: '',
            cadence: '1min',
            notificationChannels: [],
            state: 'Healthy',
            timeout: 10,
        },
        {
            id: 'abc2',
            name: 'POST http://localhost:80',
            type: 'ping',
            method: 'POST',
            target: 'http://localhost:80/',
            authMode: 'None',
            authValue: '',
            requestBody: '',
            cadence: '1min',
            notificationChannels: [],
            state: 'Healthy',
            timeout: 10,
        }
    ];

    constructor() {
        super();
        console.log("Running with mock monitors datastore");
    }

    addMonitor(program: Monitor): Promise<SaveMonitorResponse> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const newMonitor = {
                    id: 'zxcv1',
                    name: program.name,
                    type: 'ping',
                    method: 'GET',
                    target: 'http://localhost:80/',
                    authMode: 'None',
                    authValue: '',
                    requestBody: '',
                    cadence: '1min',
                    notificationChannels: [],
                    state: 'Healthy',
                    timeout: 10,
                };
                this.mockStore.push(newMonitor)
                resolve({
                    monitor: newMonitor,
                    error: undefined
                })
            }, 2000);
        });
    }

    saveMonitor(monitor: Monitor): Promise<SaveMonitorResponse> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const storedMonitor = this.mockStore.find(p => p.id === monitor.id);
                if (!storedMonitor) {
                    resolve({
                        monitor: monitor,
                        error: 'Program not found'
                    })
                } else {
                    storedMonitor.name = monitor.name;
                    resolve({
                        monitor: storedMonitor,
                        error: undefined
                    })
                }
            }, 2000);
        });
    }

    loadMonitor(id: string): Promise<GetMonitorResponse> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const monitor = this.mockStore.find(p => p.id === id);
                if (monitor) {
                    resolve({
                        monitor: monitor,
                    });
                } else {
                    resolve({
                        error: 'Monitor not found',
                    });
                }
            }, 2000);
        });
    }

    loadMonitors(nextToken?: string): Promise<MonitorsResponse> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    monitors: this.mockStore,
                    nextToken: 'qwe1',
                })
            }, 2000);
        });
    }
}

export default MockMonitorsDatastore;