import {plainFetch} from "./auth-helpers";

export interface LoginResult {
    success: boolean;
    error: string;
}

export interface SignUpResult {
    success: boolean;
    error: string;
    code: string;
}

class LoginController {
    constructor() {
    }

    public signInAsUser(email: string, password: string): Promise<LoginResult> {
        return LoginController.signIn(email, password, 'user');
    }

    public signUpAsUser(firstName: string, lastName: string, email: string, password: string): Promise<SignUpResult> {
        return LoginController.signUp(firstName, lastName, email, password);
    }

    private static signIn(email: string, password: string, type: string): Promise<LoginResult> {
        console.log(window.location.host);
        // if (window.location.host === 'localhost:3000') {
        //     return new Promise<LoginResult>((resolve, reject) => {
        //         if(email === 'fail'){
        //             setTimeout(() => resolve({
        //                 success: false,
        //                 error: 'mock failed'
        //             }), 1000);
        //         }else {
        //             setTimeout(() => resolve({
        //                 success: true,
        //                 error: ''
        //             }), 1000);
        //         }
        //     });
        // }

        return plainFetch("https://api.service-availability.io/login", {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"email": email, "password": password})
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // if (result.success) {
                    //     this.setCookie(result.auth);
                    // }
                    return {
                        success: result.success,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        success: false,
                        error: 'unknown error, unable to login'
                    }
                }
            );
    }

    private static signUp(firstName: string, lastName: string, email: string, password: string): Promise<SignUpResult> {
        return plainFetch("https://api.service-availability.io/register", {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "password": password,
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // if (result.success) {
                    //     this.setCookie(result.auth);
                    // }
                    return {
                        success: result.success,
                        error: result.error,
                        code: result.code
                    }
                },
                (error) => {
                    return {
                        success: false,
                        error: 'unknown error, unable to register',
                        code: 'unknown'
                    }
                }
            );
    }
}

export default LoginController;