export function authFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    return fetch(input, init).then(e => {
        if (e.status === 401) {
            window.location.href = '/login';
        }
        return e;
    });
}

export function plainFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    return fetch(input, init).then(e => {
        // console.log(e);
        return e;
    });
}
