import React from "react";
import PageLayout from "../../components/page-layout/page-layout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import MonitorsDatastore from "../../stores/monitors-datastore";
import MonitorsList from "../../components/monitors/monitors-list";


interface MonitorsProps {
    datastore: MonitorsDatastore;
}

function Monitors(props: MonitorsProps) {
    return (
        <PageLayout title="Monitors">
            <Button variant="contained" component={Link} to="/monitors/new" sx={{mb: 3}}>
                Add monitor
            </Button>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <MonitorsList datastore={props.datastore}/>
                    </Paper>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

export default Monitors;
