import React from "react";
import PageLayout from "../../components/page-layout/page-layout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CustomersList from "../customers/customers-list";
import MonitorsDatastore from "../../stores/monitors-datastore";
import MonitorsList from "../../components/monitors/monitors-list";
import EventsInsights from "../../components/events/events-insights";
import {Divider, Typography} from "@mui/material";

interface MainDashboardProps {
    monitorsDatastore: MonitorsDatastore;
}

function MainDashboard(props: MainDashboardProps) {
    return (
        <PageLayout title="Dashboard">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Typography>For each monitor: latency & HTTP 4xx/5xx responses</Typography>
                        <Typography>Can drag & drop monitor graphs, add new monitors to dashboard</Typography>
                        <EventsInsights datastore={props.monitorsDatastore}/>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{color: "secondary.dark"}} textAlign="left" role="presentation"
                             variant="fullWidth">Recently triggered monitors</Divider>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <MonitorsList datastore={props.monitorsDatastore}/>
                    </Paper>
                </Grid>
                <Typography>Last X events in realtime</Typography>
                <Typography>
                    //idea is to keep this page as main insights center - users should be able to see what's going on right here
                </Typography>
            </Grid>
        </PageLayout>
    );
}

export default MainDashboard;
