import {Monitor} from "../../stores/monitors-datastore";
import {Alert} from "@mui/material";
import * as React from "react";

interface MonitorHealthProps {
    monitor: Monitor;
}

function MonitorHealth(props: MonitorHealthProps) {

    return (
        <React.Fragment>
            {props.monitor.state !== 'Healthy' &&
                <Alert variant="filled" severity="warning">
                    Monitor is in alarm state since {"[TODO]"}
                </Alert>
            }
        </React.Fragment>
    );
}

export default MonitorHealth;
