import React from "react";
import PageLayout from "../../components/page-layout/page-layout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AccountDetails from "./account-details";
import PaymentDetails from "./payment-details";
import NotificationsDatastore from "../../stores/notifications-datastore";

interface AccountProps {
    notificationsDatastore: NotificationsDatastore;
}

function Account(props: AccountProps) {
    return (
        <PageLayout title="Account">
            {/*<Toolbar/>*/}
            {/*<Container maxWidth="lg" sx={{mt: 4, mb: 4}}>*/}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                            <AccountDetails notificationsDatastore={props.notificationsDatastore}/>
                        </Paper>
                        {/*<Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>*/}
                        {/*    <Settings />*/}
                        {/*</Paper>*/}
                        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', mt: 3}}>
                            <PaymentDetails />
                        </Paper>
                    </Grid>
                </Grid>
            {/*</Container>*/}
        </PageLayout>
    );
}

export default Account;
