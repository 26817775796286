import React, {useEffect, useState} from 'react';
import PageLayout from "../../components/page-layout/page-layout";
import {useNavigate} from "react-router-dom";
import MonitorsDatastore, {SaveMonitorResponse} from "../../stores/monitors-datastore";
import NotificationsDatastore, {NotificationChannel} from "../../stores/notifications-datastore";
import MonitorEditor from "../../components/monitors/monitor-editor";

interface ProviderAddNewMonitorProps {
    monitorsDatastore: MonitorsDatastore;
    notificationsDatastore: NotificationsDatastore;
}

function AddNewMonitor(props: ProviderAddNewMonitorProps) {
    const nav = useNavigate();
    function handleMonitorSaved(response: SaveMonitorResponse, nav: (path: string) => void) {
        if (!response.error) {
            nav('/monitors');
        }
    }

    function handleDiscard(nav: (path: string) => void) {
        nav('/monitors');
    }

    return (
        <PageLayout title="Add monitor">
            <MonitorEditor
                monitorsDatastore={props.monitorsDatastore}
                notificationsDatastore={props.notificationsDatastore}
                originalMonitor={undefined}
                onMonitorSaved={(r) => handleMonitorSaved(r, nav)}
                onDiscard={() => handleDiscard(nav)}
            />
        </PageLayout>
    );
}

export default AddNewMonitor;
