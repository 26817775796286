import * as React from 'react';
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from "../copyright/copyright";
import {Grid, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewListIcon from '@mui/icons-material/ViewList';
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import LayersIcon from "@mui/icons-material/Layers";
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function mainListItems(nav: (path: string) => void) {
    return (
        <React.Fragment>
            <ListItemButton onClick={() => nav('/')}>
                <ListItemIcon>
                    <DashboardIcon color="secondary"/>
                </ListItemIcon>
                <ListItemText primary="Dashboard"/>
            </ListItemButton>
            <ListItemButton onClick={() => nav('/monitors')}>
                <ListItemIcon>
                    <ViewListIcon color="secondary"/>
                </ListItemIcon>
                <ListItemText primary="Monitors"/>
            </ListItemButton>
            {/*<ListItemButton onClick={() => nav('/customers')}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <PeopleIcon color="secondary"/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Customers"/>*/}
            {/*</ListItemButton>*/}
        </React.Fragment>
    );
}

function secondaryListItems(nav: (path: string) => void) {
    return (
        <React.Fragment>
            <ListSubheader component="div" inset>
            </ListSubheader>
            <ListItemButton onClick={() => nav('/account')}>
                <ListItemIcon>
                    <SettingsIcon color="secondary"/>
                </ListItemIcon>
                <ListItemText primary="Account"/>
            </ListItemButton>
            {/*<ListItemButton onClick={() => nav('/subscription')}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <LayersIcon color="secondary"/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Subscription"/>*/}
            {/*</ListItemButton>*/}
        </React.Fragment>
    );
}

function PageLayoutContent(props: PageLayoutProps) {
    const [open, setOpen] = React.useState(!localStorage.drawerOpen);
    const toggleDrawer = () => {
        setOpen(!open);
        localStorage.drawerOpen = !open;
    };

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{pr: '24px'}}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                                sx={{marginRight: '36px', ...(open && {display: 'none'}),}}>
                        <MenuIcon color="action"/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="action"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        {props.title}
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1]}}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                <List component="nav">
                    {mainListItems(useNavigate())}
                    <Divider sx={{my: 1}}/>
                    {secondaryListItems(useNavigate())}
                </List>
            </Drawer>
            <Box component="main" sx={{
                backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}>
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4, height: '90%',}}>
                    <Grid container spacing={5} sx={{height: '100%',}}>
                        <Grid item xs={12}>
                            {props.children}
                        </Grid>
                        <Grid item xs={12}>
                            <Box component="footer" alignItems="end">
                                <Copyright sx={{pt: 4}}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

interface PageLayoutProps {
    children?: React.ReactNode,
    title: string,
}

export default function PageLayout(props: PageLayoutProps) {
    return (
        <PageLayoutContent title={props.title}>
            {props.children}
        </PageLayoutContent>
    );
}