import * as React from 'react';
import {Box, Divider, Grid} from "@mui/material";
import MonitorsDatastore, {
    Monitor, MonitorsResponse,
} from "../../stores/monitors-datastore";
import EventGraph from "./event-graph";

interface EventsInsightsState {
    monitors: Monitor[];
    loading: boolean;
}

interface EventsInsightsProps {
    datastore: MonitorsDatastore;
}

class EventsInsights extends React.Component<EventsInsightsProps, EventsInsightsState> {
    private datastore: MonitorsDatastore;

    constructor(props: EventsInsightsProps) {
        super(props);
        this.state = {
            monitors: [],
            loading: false,
        };

        this.datastore = this.props.datastore;
        this.loadMonitors = this.loadMonitors.bind(this);
    }

    componentDidMount() {
        this.loadMonitors().then(response => {
            this.setState({
                monitors: response.monitors,
            });
        })
    }

    loadMonitors(nextToken?: string): Promise<MonitorsResponse> {
        this.setState({loading: true});
        return this.datastore.loadMonitors(nextToken).then(response => {
            this.setState({loading: false});
            return response;
        });
    }

    render() {
        return (
            <React.Fragment>
                <Box>
                    {this.state.monitors.length > 0 &&
                        <EventGraph datastore={this.props.datastore} monitor={this.state.monitors[0]}/>
                    }
                </Box>
            </React.Fragment>
        );
    }
}

export default EventsInsights;

