import React, {useEffect, useState} from 'react';
import PageLayout from "../../components/page-layout/page-layout";
import {Alert, AlertTitle, Box, CircularProgress, Divider, Grid} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import MonitorsDatastore, {Monitor, SaveMonitorResponse} from "../../stores/monitors-datastore";
import NotificationsDatastore from "../../stores/notifications-datastore";
import MonitorEditor from "../../components/monitors/monitor-editor";
import EventGraph from "../../components/events/event-graph";
import MonitorHealth from "../../components/monitors/monitor-health";
import EventsList from "../../components/events/events-list";

interface ViewMonitorProps {
    monitorsDatastore: MonitorsDatastore;
    notificationsDatastore: NotificationsDatastore;
}

function ViewMonitor(props: ViewMonitorProps) {
    const {id} = useParams();
    const nav = useNavigate();

    const [originalMonitor, setOriginalMonitor] = useState(undefined as Monitor | undefined);
    const [loadMonitorError, setLoadMonitorError] = useState('');
    const [loadMonitorInProgress, setLoadMonitorInProgress] = useState(true);

    console.log('ID: ' + id);

    useEffect(() => {
        if (!id) {
            setLoadMonitorError('Monitor not found');
        } else {
            props.monitorsDatastore.loadMonitor(id).then(response => {
                setLoadMonitorInProgress(false);
                if (response.error) {
                    setLoadMonitorError(response.error);
                } else {
                    console.log(response.monitor!);
                    setOriginalMonitor(response.monitor);
                }
            });
        }
    }, []);

    // function saveMonitor(monitor: Monitor, nav: (path: string) => void) {
    //     setSaveMonitorInProgress(true);
    //     setSaveMonitorError('');
    //     return props.monitorsDatastore.saveMonitor(monitor).then(response => {
    //         setSaveMonitorInProgress(false);
    //         if (response.error) {
    //             setSaveMonitorError(response.error)
    //         } else {
    //             nav('/monitors');
    //         }
    //     });
    // }

    function handleMonitorSaved(response: SaveMonitorResponse, nav: (path: string) => void) {
        if (!response.error) {
            nav('/monitors');
        }
    }

    function handleDiscard(nav: (path: string) => void) {
        nav('/monitors');
    }

    return <>
        {!loadMonitorInProgress &&
            <PageLayout title="View monitor">
                {loadMonitorError &&
                    <Grid item xs={12} textAlign="right">
                        <Alert severity="error" onClose={() => {
                        }}>
                            <AlertTitle>Error</AlertTitle>
                            {loadMonitorError}
                        </Alert>
                    </Grid>
                }
                {loadMonitorError !== undefined &&
                    <Box sx={{display: 'grid', rowGap: 3}}>
                        <MonitorHealth monitor={originalMonitor!}/>
                        <EventGraph datastore={props.monitorsDatastore} monitor={originalMonitor!}/>
                        <MonitorEditor
                            monitorsDatastore={props.monitorsDatastore}
                            notificationsDatastore={props.notificationsDatastore}
                            originalMonitor={originalMonitor}
                            onMonitorSaved={(r) => handleMonitorSaved(r, nav)}
                            onDiscard={() => handleDiscard(nav)}
                        />
                        {/*<Grid item xs={12}>*/}
                        {/*    <Divider sx={{color: "secondary.dark"}} textAlign="left" role="presentation"*/}
                        {/*             variant="fullWidth">Latest events</Divider>*/}
                        {/*</Grid>*/}
                        {/*<EventsList datastore={props.monitorsDatastore} monitor={originalMonitor!}/>*/}
                    </Box>
                }
                {loadMonitorInProgress &&
                    <CircularProgress size={14}/>
                }
            </PageLayout>
        }
    </>;
}

export default ViewMonitor;
