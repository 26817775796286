import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/login';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NoSuchPage from "./pages/errors/no-such-page";
import Layout from "./components/layout/layout";
import MainDashboard from "./pages/dashboard/dashboard";
import Monitors from "./pages/monitors/monitors";
import ProviderCustomers from "./pages/customers/customers";
import AddNewMonitor from "./pages/monitors/add-monitor";
import ViewMonitor from "./pages/monitors/view-monitor";

import MonitorsDatastore from "./stores/monitors-datastore";
import MockMonitorsDatastore from "./stores/mock-monitors-datastore";
import NotificationsDatastore from "./stores/notifications-datastore";
import MockNotificationsDatastore from "./stores/mock-notifications-datastore";
import Account from "./pages/account/account";
import Welcome from "./pages/welcome/welcome";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const monitorsDatastore = (window.location.host === 'alocalhost:3000') ? new MockMonitorsDatastore() : new MonitorsDatastore();
const notificationsDatastore = (window.location.host === 'alocalhost:3000') ? new MockNotificationsDatastore() : new NotificationsDatastore();

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout/>}>
                {/*<Route index element={<Welcome monitorsDatastore={monitorsDatastore}/>}/>*/}
                <Route index element={<MainDashboard monitorsDatastore={monitorsDatastore}/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/monitors" element={<Monitors datastore={monitorsDatastore}/>}/>
                <Route path="/monitors/new" element={<AddNewMonitor monitorsDatastore={monitorsDatastore} notificationsDatastore={notificationsDatastore}/>}/>
                <Route path="/monitors/:id" element={<ViewMonitor monitorsDatastore={monitorsDatastore} notificationsDatastore={notificationsDatastore}/>}/>
                <Route path="/account" element={<Account notificationsDatastore={notificationsDatastore}/>}/>
                <Route path="*" element={<NoSuchPage/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();