import React from 'react';
import './login.css';
import LoginForm from "../../components/login/login-form";
import {useNavigate} from "react-router-dom";
import Copyright from "../../components/copyright/copyright";

function Login() {
    return (
        <div className="container text-center overflow-hidden min-vh-100">
            <div className="row my-5">
                <div className="col pt-sm-5">
                    <LoginForm loginType="provider" registrationAllowed={true} navigate={useNavigate()}></LoginForm>
                </div>
            </div>
            <div className="pt-lg-5">
                <Copyright/>
            </div>
        </div>
    );
}

export default Login;
