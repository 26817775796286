import NotificationsDatastore, {NotificationChannelsResponse} from "./notifications-datastore";

class MockNotificationsDatastore extends NotificationsDatastore{
    private mockStore = [
        {
            id: 'abc1',
            type: 'slack',
            handle: 'handle1',
            channel: '#general',
            workspace: 'EZ-Dev',
        },
        {
            id: 'abc2',
            type: 'email',
            handle: 'zmeev.ev@google.com',
            channel: '#general2',
            workspace: 'EZ-Dev2',
        }
    ];

    constructor() {
        super();
        console.log("Running with mock notifications datastore");
    }

    loadNotificationChannels(): Promise<NotificationChannelsResponse> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    channels: this.mockStore
                })
            }, 2000);
        });
    }
}

export default MockNotificationsDatastore;