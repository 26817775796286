import * as React from 'react';
import {useEffect, useState} from 'react';
import Title from "../../components/page-layout/title";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import NotificationsDatastore, {NotificationChannel} from "../../stores/notifications-datastore";
import {Edit, Info} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

interface AccountDetailsProps {
    notificationsDatastore: NotificationsDatastore;
}

export default function AccountDetails(props: AccountDetailsProps) {
    const [accountEmail, setAccountEmail] = React.useState('zmeev.ev@gmail.com');

    const [contacts, setContacts] = React.useState([] as NotificationChannel[]);
    const [loadingContacts, setLoadingContacts] = React.useState(false);

    const [addContactOpen, setAddContactOpen] = React.useState(false);
    const [addContactId, setAddContactId] = React.useState('');
    const [addContactType, setAddContactType] = React.useState('email');
    const [addContactValue, setAddContactValue] = React.useState('');
    const [addContactInProgress, setAddContactInProgress] = React.useState(false);

    const [addContactValueValid, setAddContactValueValid] = useState(true);

    const [addContactError, setAddContactError] = React.useState('');

    useEffect(() => {
        setLoadingContacts(true);
        props.notificationsDatastore.loadNotificationChannels().then(response => {
            setLoadingContacts(false);
            if (!response.error) {
                setContacts(response.channels);
            } else {
                console.log("Unable to get notification channels:", response.error);
            }
        });
    }, []);

    function openAddContactDialog() {
        setAddContactId('')
        setAddContactType('email')
        setAddContactValue('')
        setAddContactOpen(true);
    }

    function openEditContactDialog(contact: NotificationChannel) {
        setAddContactId(contact.id ?? '')
        setAddContactType(contact.type ?? 'email')
        setAddContactValue(contact.handle ?? '')
        setAddContactOpen(true);
    }

    function handleSetAddContactType(value: any) {
        setAddContactType(value);
    }

    function handleSetAddContactValue(value: any) {
        setAddContactValue(value);
        setAddContactValueValid(true);
    }

    function handleAddContactClosed() {
        setAddContactError('');
        setAddContactOpen(false);
    }

    function handleRemoveContact(id: string) {
        setAddContactInProgress(true);
        props.notificationsDatastore.removeNotificationChannel(id).then((response) => {
            setAddContactInProgress(false);
            if ((response.error as any) instanceof Error) {
                console.log(response.error);
                setAddContactError(`Unable to remove contact: ${response.error}`);
            } else if (response.error) {
                console.log("Contact not removed: ", response.error);
                setAddContactError(`Unable to remove contact: ${response.error}`);
            } else {
                setAddContactOpen(false);
            }
        }).catch(error => {
            console.log("Contact removal failed: ", error);
        });
    }

    function handleAddContact() {
        setAddContactInProgress(true);
        setAddContactError('');
        if (addContactType === 'slack') {
            props.notificationsDatastore.addSlackWorkspace().then((response) => {
                setAddContactInProgress(false);
                setAddContactOpen(false);
                const url = response.url!!;
                window.open(url);
            });
        } else {
            const channel = {
                type: addContactType,
                handle: addContactValue,
            } as NotificationChannel;
            props.notificationsDatastore.saveNotificationChannel(channel).then((response) => {
                if ((response.error as any) instanceof Error) {
                    console.log(response.error);
                    setAddContactError(`Unable to save contact: ${response.error}`);
                } else if (response.error) {
                    console.log("Channel not saved: ", response.error);
                    setAddContactError(`Unable to save contact: ${response.error}`);
                } else {
                    setContacts(response.channels);
                }
                setAddContactInProgress(false);
                setAddContactOpen(false);
            });
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Title>Account details</Title>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" sx={{'& .MuiTextField-root': {m: 0}}} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField id="accountEmail" label="Email" size="small" value={accountEmail}
                                           aria-readonly={true} disabled={true} variant="standard"/>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{mt: 3}}>
                    <Title>Notification contacts</Title>
                </Grid>
                <Grid item xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: 30}}></TableCell>
                                {/*<TableCell>Name</TableCell>*/}
                                <TableCell sx={{width: 90}}>Type</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contacts.map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell>
                                        <IconButton onClick={() => openEditContactDialog(contact)} color="primary"
                                                    aria-label="Edit">
                                            <Edit/>
                                        </IconButton>
                                    </TableCell>
                                    {/*<TableCell>{contact.name}</TableCell>*/}
                                    <TableCell>{contact.type}</TableCell>
                                    <TableCell>{contact.workspace + " " + contact.channel}</TableCell>
                                </TableRow>
                            ))}
                            {/*<TableRow key="add-inline">*/}
                            {/*    <TableCell colSpan={3} align={"center"}>*/}
                            {/*        <Button fullWidth variant="text" onClick={() => openAddContactDialog()} sx={{mt: 0}}*/}
                            {/*                disabled={loadingContacts}>*/}
                            {/*            {loadingContacts && <CircularProgress size={14}/>}*/}
                            {/*            {!loadingContacts && 'Add contact'}*/}
                            {/*        </Button>*/}
                            {/*    </TableCell>*/}
                            {/*</TableRow>*/}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="text" onClick={() => openAddContactDialog()} sx={{mt: 0}}
                            disabled={loadingContacts}>
                        {loadingContacts && <CircularProgress size={14}/>}
                        {!loadingContacts && 'Add contact'}
                    </Button>
                </Grid>
                <Dialog open={addContactOpen} onClose={() => handleAddContactClosed()}>
                    <DialogTitle>Add contact</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{mb: 3}}>
                            Please enter new contact details here. We will send notifications about events with
                            associated monitors.
                        </DialogContentText>
                        <Select labelId="contactType" id="contactTypeSelect" value={addContactType} label="Type"
                                sx={{mr: 1, mb: 2, minWidth: 90}} variant="standard"
                                onChange={(event) => handleSetAddContactType(event.target.value)} size="small">
                            <MenuItem value={'sms'}>SMS</MenuItem>
                            <MenuItem value={'email'}>Email</MenuItem>
                            <MenuItem value={'slack'}>Slack</MenuItem>
                        </Select>

                        {addContactType === 'slack' && !addContactId &&
                            <Box><Info sx={{mr: 1}} color="info"/>Once you click "Add" button below,
                                you will be redirected to Slack website where you can finish contact creation</Box>
                        }

                        {addContactType === 'slack' && addContactId &&
                            <Box><Info sx={{mr: 1}} color="info"/>Slack contacts cannot be updated, please create a
                                new contact instead</Box>
                        }

                        {addContactType !== 'slack' &&
                            <TextField id="contactValue" label="" size="small" variant="standard"
                                       value={addContactValue}
                                       InputLabelProps={{shrink: true}} error={!addContactValueValid}
                                       onChange={(event) => handleSetAddContactValue(event.target.value)}/>
                        }
                        {addContactError &&
                            <Alert severity="error" onClose={() => setAddContactError('')}>
                                {addContactError}
                            </Alert>
                        }
                    </DialogContent>
                    <DialogActions>
                        {addContactId && <Button onClick={() => handleRemoveContact(addContactId)}
                                                 disabled={addContactInProgress} color="error">
                            {addContactInProgress && <CircularProgress size={25} color={"inherit"}/>}
                            {!addContactInProgress && 'Remove'}
                        </Button>}
                        <Button onClick={() => handleAddContactClosed()}
                                disabled={addContactInProgress}>Cancel</Button>
                        <Button onClick={() => handleAddContact()}
                                disabled={addContactInProgress || addContactType === 'slack'}>
                            {addContactInProgress && <CircularProgress size={25} color={"inherit"}/>}
                            {!addContactInProgress && 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </React.Fragment>
    );
}
