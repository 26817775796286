import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, useTheme} from "@mui/material";
import MonitorsDatastore, {Monitor, MonitorEventData, MonitorEventsResponse,} from "../../stores/monitors-datastore";
import Plot from "react-plotly.js";

interface EventGraphProps {
    datastore: MonitorsDatastore;
    monitor: Monitor;
}

function EventGraph(props: EventGraphProps) {
    const theme = useTheme();

    const [events, setEvents] = useState([] as MonitorEventData[]);
    const [eventsLoading, setEventsLoading] = useState(false);

    useEffect(() => {
        loadEvents(props.monitor.id!).then(response => {
            setEvents(response.events);
        })
    }, []);

    function loadEvents(monitorId: string): Promise<MonitorEventsResponse> {
        setEventsLoading(true);
        return props.datastore.loadMonitorEvents(monitorId).then(response => {
            setEventsLoading(false);
            return response;
        });
    }

    const data = [
        {
            x: events.map(e => new Date(e.timestamp).toISOString()),
            y: events.map(e => e.latency || 0),
            mode: "lines",
        },
    ];
    const layout = {
        colorway: [theme.palette.primary.dark],
        title: {
            text: "Latency (ms)",
            font: {
                color: theme.palette.primary.main,
                size: 12,
            },
            pad: {
                t: 0,
                b: 0,
            },
        },
        plot_bgcolor: theme.palette.background.default,
        paper_bgcolor: theme.palette.background.default,
        height: 220,
        margin: {
            l: 30,
            r: 10,
            b: 40,
            t: 50,
        },
    };

    return (
        <React.Fragment>
            <Box>
                <Plot data={data} layout={layout} style={{width: '100%', height: '100%', paddingLeft: '0'}}/>
            </Box>
        </React.Fragment>
    );
}

export default EventGraph;

