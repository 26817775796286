import {Outlet} from "react-router-dom";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {Box, GlobalStyles, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import Copyright from "../copyright/copyright";

const mdTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#44BB44',
        },
        secondary: {
            main: '#338a3e',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'mono',
            'monospace',
        ].join(','),
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px var(--primary-weak) inset',
                        WebkitTextFillColor: 'var(--text-primary)',
                    },
                },
            },
        }
    }
});

interface LayoutProps {
    children?: React.ReactNode,
}

export default function Layout(props: LayoutProps) {
    return (
        <ThemeProvider theme={mdTheme}>
            <GlobalStyles
                styles={{
                    ":root": {
                        // "--primary-weak": mdTheme.palette.primary[mdTheme.palette.mode as "dark" | "light"],
                        "--primary-weak": '#282c34',
                        "--text-primary": mdTheme.palette.text.primary,
                    },
                }}
            />
            <CssBaseline/>
            <Outlet/>
        </ThemeProvider>
    )
}

