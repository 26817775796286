import React from 'react';
import './login-form.css';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from '@mui/material';
import LoginController from '../../stores/login-controller';

interface LoginFormState {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    loginError?: string;
    signUpError?: string;
    loginMode: boolean;
    submitting: boolean;
    loginInvalid: boolean;
    passwordInvalid: boolean;
    firstNameInvalid: boolean;
    lastNameInvalid: boolean;
    loginInvalidError?: string;
}

interface LoginFormProps {
    loginType: string;
    registrationAllowed: boolean;
    navigate: (path: string) => void;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    private loginController: LoginController;

    constructor(props: LoginFormProps) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            loginError: undefined,
            signUpError: undefined,
            loginMode: true,
            submitting: false,
            loginInvalid: false,
            passwordInvalid: false,
            firstNameInvalid: false,
            lastNameInvalid: false,
            loginInvalidError: undefined,
        };
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSignUp = this.handleSignUp.bind(this);
        this.switchMode = this.switchMode.bind(this);
        this.resetErrors = this.resetErrors.bind(this);
        this.loginController = new LoginController();
    }

    handleFirstNameChange(event: any) {
        this.setState({
            firstName: event.target.value,
            firstNameInvalid: false,
        });
    }

    handleLastNameChange(event: any) {
        this.setState({
            lastName: event.target.value,
            lastNameInvalid: false,
        });
    }

    handleEmailChange(event: any) {
        this.setState({
            email: event.target.value,
            loginInvalid: false,
        });
    }

    handlePasswordChange(event: any) {
        this.setState({
            password: event.target.value,
            passwordInvalid: false,
        });
    }

    switchMode(_: any) {
        console.log(this.state);
        this.setState({
            loginMode: !this.state.loginMode,
            loginInvalid: false,
            passwordInvalid: false,
            firstNameInvalid: false,
            lastNameInvalid: false,
        });
    }

    resetErrors(_: any) {
        console.log(this.state);
        this.setState({
            loginError: undefined,
            signUpError: undefined,
        });
    }

    handleSignIn(event: any) {
        event.preventDefault();

        let invalid = false;
        if (!this.state.email) {
            this.setState({loginInvalid: true});
            invalid = true;
        }
        if (!this.state.password) {
            this.setState({passwordInvalid: true});
            invalid = true;
        }
        if(invalid) {
            return;
        }

        this.setState({
            loginError: undefined,
            submitting: true,
        });

        this.loginController.signInAsUser(this.state.email, this.state.password).catch(e => e).then(r => {
            if (r.success) {
                this.props.navigate('/');
                console.log('logged in');
            } else {
                console.log('login failed');
                this.setState({
                    loginError: r.error,
                    submitting: false,
                });
            }
        });
    }

    handleSignUp(event: any) {
        event.preventDefault();

        let invalid = false;
        if (!this.state.email) {
            this.setState({loginInvalid: true});
            invalid = true;
        }
        if (!this.state.password) {
            this.setState({passwordInvalid: true});
            invalid = true;
        }
        if (!this.state.firstName) {
            this.setState({firstNameInvalid: true});
            invalid = true;
        }
        if (!this.state.lastName) {
            this.setState({lastNameInvalid: true});
            invalid = true;
        }
        if(invalid) {
            console.log("invalid login");
            return;
        }

        this.setState({
            loginError: undefined,
            submitting: true,
        });

        console.log(event);
        const state = this.state;
        this.loginController.signUpAsUser(state.firstName, state.lastName, state.email, state.password).then(r => {
            console.log(r);
            if (r.success) {
                this.props.navigate('/');
                console.log('registered and logged in');
            } else {
                console.log('registration failed');
                this.setState({
                    signUpError: r.error,
                    submitting: false,
                });
            }
        });
    }

    render() {
        return this.state.loginMode ? this.signIn() : this.signUp();
    }

    private signIn() {
        return <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                <Typography component="h1" variant="h5">Sign in</Typography>
                <Box component="form" onSubmit={this.handleSignIn} noValidate sx={{mt: 1}}>
                    <TextField margin="normal" required fullWidth name="email" label="Email Address"
                               error={this.state.loginInvalid} id="email" autoComplete="email" autoFocus
                               onChange={this.handleEmailChange}/>
                    <TextField margin="normal" required fullWidth name="password" label="Password"
                               error={this.state.passwordInvalid} type="password" id="password"
                               autoComplete="current-password" onChange={this.handlePasswordChange}/>
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} disableRipple>

                        {!this.state.submitting && 'Sign In'}
                        {this.state.submitting && <CircularProgress size={25} color="inherit"/>}
                    </Button>
                    {this.state.loginError &&
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={this.resetErrors}>
                                {/*<AlertTitle>Error</AlertTitle>*/}
                                {this.state.loginError}
                            </Alert>
                        </Grid>
                    }
                    <Grid container sx={{mt: 2}}>
                        <Grid item xs textAlign="left">
                            <Link href="#" variant="body2">Forgot password?</Link>
                        </Grid>
                        <Grid item xs textAlign="right">
                            <Link href="#" variant="body2" onClick={this.switchMode}>Sign Up</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>;
    }

    private signUp() {
        return <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                <Typography component="h1" variant="h5">Sign up</Typography>
                <Box component="form" noValidate onSubmit={this.handleSignUp} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth id="firstName" label="First Name"
                                       onChange={this.handleFirstNameChange} error={this.state.firstNameInvalid}
                                       name="firstName" autoFocus autoComplete="given-name"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth id="lastName" label="Last Name"
                                       onChange={this.handleLastNameChange} error={this.state.lastNameInvalid}
                                       name="lastName" autoComplete="family-name"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth id="email" label="Email Address"
                                       onChange={this.handleEmailChange} error={this.state.loginInvalid}
                                       name="email" autoComplete="email"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth id="password" label="Password"
                                       onChange={this.handlePasswordChange} error={this.state.passwordInvalid}
                                       name="password" type="password" autoComplete="new-password"/>
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} disableRipple>
                        {!this.state.submitting && 'Sign Up'}
                        {this.state.submitting && <CircularProgress size={25} color="inherit"/>}
                    </Button>
                    {this.state.signUpError &&
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={this.resetErrors}>
                                {/*<AlertTitle>Error</AlertTitle>*/}
                                {this.state.signUpError}
                            </Alert>
                        </Grid>
                    }
                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                        <Grid item textAlign="right">
                            <Link href="#" variant="body2" onClick={this.switchMode}>
                                Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>;
    }
}

export default LoginForm;