import {GetSlackUrlResponse, SaveMonitorResponse} from "./monitors-datastore";
import {authFetch} from "./auth-helpers";

export interface NotificationChannelsResponse {
    channels: NotificationChannel[];
    error?: string;
}

export interface RemoveNotificationChannelResponse {
    error?: string;
}

export interface SaveNotificationChannelResponse {
    channels: NotificationChannel[];
    error?: string;
}

export interface NotificationChannel {
    id: string;
    type: string;
    name?: string;
    handle: string;
    channel: string;
    workspace: string;
}

class NotificationsDatastore {
    constructor() {
    }

    addSlackWorkspace(): Promise<GetSlackUrlResponse> {
        return authFetch(`https://api.service-availability.io/oauth?action=addSlackWorkspace`, {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        url: result.url,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        error: error,
                    }
                }
            );
    }

    loadNotificationChannels(): Promise<NotificationChannelsResponse> {
        return authFetch('https://api.service-availability.io/notification/channels', {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        channels: result.channels,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        channels: [],
                        error: error,
                    }
                }
            );
    }

    removeNotificationChannel(contactId: string): Promise<RemoveNotificationChannelResponse> {
        return authFetch(`https://api.service-availability.io/notification/channels/${contactId}`, {
            credentials: 'include',
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        error: error
                    }
                }
            );
    }

    saveNotificationChannel(channel: NotificationChannel): Promise<SaveNotificationChannelResponse> {
        return authFetch(`https://api.service-availability.io/notification/channels`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(channel),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    return {
                        channels: result.channels,
                        error: result.error
                    }
                },
                (error) => {
                    return {
                        channels: [],
                        error: error,
                    }
                }
            );
    }
}

export default NotificationsDatastore;